<template>
  <a-layout class='index animated fadeIn'>
    <a-card>
      <a-form ref="ruleForm" :model="form" :rules="rules" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-form-item ref="password" label="旧密码" name="password">
          <a-input-password v-model:value="form.password" placeholder="请输入旧密码" />
        </a-form-item>
        <a-form-item ref="new_password" label="新密码" name="new_password">
          <a-input-password v-model:value="form.new_password" placeholder="请输入新密码" />
        </a-form-item>
        <a-form-item ref="new_repassword" label="再次输入密码" name="new_repassword">
          <a-input-password v-model:value="form.new_repassword" placeholder="请再次输入密码" />
        </a-form-item>
        <a-form-item :wrapper-col="{ span: 8, offset: 2 }">
          <a-button type="primary" @click="onSubmit">
            保存
          </a-button>
        </a-form-item>
      </a-form>
    </a-card>
  </a-layout>
</template>

<script>
import { ref, onMounted, reactive, toRefs, inject } from 'vue';
import  {changePassword} from '@/api/system'
import { $iscode } from "@/utils/app";
// 初始化默认筛选项数值
let defForm = {
  password: '',
  new_password:'',
  new_repassword:''
};
export default {
  setup() {
    //pass
    const form = ref({ ...defForm });
    const state = reactive({
      labelCol: { span: 2 },
      wrapperCol: { span: 5},
      rules: {
        password: [
          {
            required: true,
            message: '请输入旧密码',
          }
        ],
        new_password: [
          {
            required: true,
            message: '请输入新密码',
          }
        ],
        new_repassword: [
          {
            required: true,
            message: '请再次输入新密码',
          }
        ],
      },
    });
    const $message=inject('$message')
    const $router = inject("$router");
    const ruleForm = ref();
    // 提交
    const onSubmit = () => {
      if(form.value.new_password!=form.value.new_repassword){
        $message.error('两次密码输入不一致')
        return
      }
      ruleForm.value
        .validate()
        .then(async() => {
          let result = await changePassword({...form.value}).then(res=>res.data).catch(error=>error)
          if ($iscode(result)) {
            $message.success(result.msg);
            localStorage.clear();
            $router.push('/login');
          } else {
            $message.error(result.msg);
          }
        })
        .catch((error) => {
          console.log(1111)
          $message.error('请完善表单')
        });
    };
    return {
      form,
      ...toRefs(state),
      ruleForm,
      onSubmit,
    };
  },
};
</script>
